import React from 'react'
import Logo from './logoConay.jpg'


export default function Navbar(props) {


    function changeLanguage() {
        props.toggleLanguage()
    }

    function btnClick() {
        window.open("https://conay-my.sharepoint.com/:f:/g/personal/rfernandez_conaysa_com/EoX22XW_RshJrPOVvXdJz94BSl_EtClPGkWKYJR-6ZmHPw?e=2buKmC");
    }


    return (
    <>
        {props.currentLanguage === "English"
        ?
        <>
            <div className="navbar navbar-light bg-light">
                <img className="nav-logo" src={Logo} alt="ConayLogo"></img>
                <nav className="nav">
                    <button className="About Us" onClick={props.executeScrolltoAboutUs}>Acerca de nosotros</button>
                    <button className="Services" onClick={props.executeScrolltoServices}>Servicios</button>
                    <button className="About Us" onClick={props.executeScrolltoFleet}>Flota</button>
                    <button className="Concact" onClick={props.executeScrolltoContact}>Contacto</button>
                    <button className="Concact" onClick={btnClick}>Itinerario</button>
                    <button className="language" onClick={changeLanguage}>{props.currentLanguage}</button>
                </nav>
            </div>
        </>
        :
        <>
            <div className="navbar navbar-light bg-light">
                <img className="nav-logo" src={Logo} alt="ConayLogo"></img>
                <nav className="nav">
                    <button className="About Us" onClick={props.executeScrolltoAboutUs}>About Us</button>
                    <button className="Services" onClick={props.executeScrolltoServices}>Services</button>
                    <button className="About Us" onClick={props.executeScrolltoFleet}>Fleet</button>
                    <button className="Concact" onClick={props.executeScrolltoContact}>Contact</button>
                    <button className="Concact" onClick={btnClick}>Schedules</button>
                    <button className="language" onClick={changeLanguage}>{props.currentLanguage}</button>
                </nav>
            </div>
        </>
    }
    </>
    )
}
