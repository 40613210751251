import React from 'react'
import VesselSmall from './VesselSmall.jsx'


export default function Fleet(props) {
    return (
        <>
            {props.currentLanguage === "English"
                ?
                <>
                    <div className="section-title2">Flota</div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4">
                                <VesselSmall
                                    currentLanguage={props.currentLanguage}
                                    name="PIRAVERA"
                                    vesselType="Remolcador/Empujador"
                                    length="35m"
                                    beam="10m"
                                    engine="4500 HP"
                                    built="2011"
                                    status="Owned"
                                    flag="Paraguay"
                                    marineTrafficLink="https://www.marinetraffic.com/es/ais/details/ships/shipid:674398/mmsi:755106000/imo:9627813/vessel:PIRAVERA"
                                />
                            </div>
                            <div className="col-sm-4">
                                <VesselSmall
                                    currentLanguage={props.currentLanguage}
                                    name="RIO PIRARETA"
                                    vesselType="Remolcador/Empujador"
                                    length="41.5m"
                                    beam="12m"
                                    engine="3600 HP"
                                    built="2007"
                                    status="Owned"
                                    flag="Paraguay"
                                    marineTrafficLink="https://www.marinetraffic.com/es/ais/details/ships/shipid:784099/mmsi:755003000/imo:0/vessel:RIO_PIRARETA"
                                />
                            </div>



                            <div className="col-sm-4">
                                <VesselSmall
                                    currentLanguage={props.currentLanguage}
                                    name="PIRAYU"
                                    vesselType="Remolcador/Empujador"
                                    length="32.18m"
                                    beam="10.5m"
                                    engine="3000 HP"
                                    built="1997"
                                    status="Chartered"
                                    flag="Paraguay"

                                />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">

                                <VesselSmall
                                    currentLanguage={props.currentLanguage}
                                    name="Conay I"
                                    vesselType="Barcaza"
                                    length="78m"
                                    beam="23.6m"
                                    capacity="320 TEU"
                                    plugs="100"
                                    built="2007"
                                    status="Owned"
                                    flag="Paraguay"

                                />
                            </div>
                            <div className="col-sm-3">
                                <VesselSmall
                                    currentLanguage={props.currentLanguage}
                                    name="Conay II"
                                    vesselType="Barcaza"
                                    length="78m"
                                    beam="23.6m"
                                    capacity="320 TEU"
                                    plugs="100"
                                    built="2008"
                                    status="Owned"
                                    flag="Paraguay"
                                />

                            </div>
                            <div className="col-sm-3">
                                <VesselSmall
                                    currentLanguage={props.currentLanguage}
                                    name="Care I"
                                    vesselType="Barcaza"
                                    length="78m"
                                    beam="23.6m"
                                    capacity="320 TEU"
                                    plugs="100"
                                    built="2006"
                                    status="Chartered"
                                    flag="Argentina"

                                />

                            </div>
                            <div className="col-sm-3">
                                <VesselSmall
                                    currentLanguage={props.currentLanguage}
                                    name="Care II"
                                    vesselType="Barcaza"
                                    length="78m"
                                    beam="23.6m"
                                    capacity="320 TEU"
                                    plugs="100"
                                    built="2009"
                                    status="Chartered"
                                    flag="Argentina"

                                />

                            </div>

                        </div>
                    </div>

                </>
                :
                <>
                    <div className="section-title2"> Fleet</div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4">
                                <VesselSmall
                                    currentLanguage={props.currentLanguage}
                                    name="PIRAVERA"
                                    vesselType="Pusher Tug Boat"
                                    length="35m"
                                    beam="10m"
                                    engine="4500 HP"
                                    built="2011"
                                    status="Owned"
                                    flag="Paraguay"
                                    marineTrafficLink="https://www.marinetraffic.com/es/ais/details/ships/shipid:674398/mmsi:755106000/imo:9627813/vessel:PIRAVERA"
                                />
                            </div>
                            <div className="col-sm-4">
                                <VesselSmall
                                    currentLanguage={props.currentLanguage}
                                    name="RIO PIRARETA"
                                    vesselType="Pusher Tug Boat"
                                    length="41.5m"
                                    beam="12m"
                                    engine="3600 HP"
                                    built="2007"
                                    status="Owned"
                                    flag="Paraguay"
                                    marineTrafficLink="https://www.marinetraffic.com/es/ais/details/ships/shipid:784099/mmsi:755003000/imo:0/vessel:RIO_PIRARETA"
                                />
                            </div>



                            <div className="col-sm-4">
                                <VesselSmall
                                    currentLanguage={props.currentLanguage}
                                    name="PIRAYU"
                                    vesselType="Pusher Tug Boat"
                                    length="32.18m"
                                    beam="10.5m"
                                    engine="3000 HP"
                                    built="1997"
                                    status="Chartered"
                                    flag="Paraguay"

                                />
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <VesselSmall
                                        currentLanguage={props.currentLanguage}
                                        name="Conay I"
                                        vesselType="Barge"
                                        length="78m"
                                        beam="23.6m"
                                        capacity="320 TEU"
                                        plugs="100"
                                        built="2007"
                                        status="Owned"
                                        flag="Paraguay"

                                    />
                                </div>
                                <div className="col-sm-3">
                                    <VesselSmall
                                        currentLanguage={props.currentLanguage}
                                        name="Conay II"
                                        vesselType="Barge"
                                        length="78m"
                                        beam="23.6m"
                                        capacity="320 TEU"
                                        plugs="100"
                                        built="2008"
                                        status="Owned"
                                        flag="Paraguay"
                                    />

                                </div>
                                <div className="col-sm-3">
                                    <VesselSmall
                                        currentLanguage={props.currentLanguage}
                                        name="Care I"
                                        vesselType="Barge"
                                        length="78m"
                                        beam="23.6m"
                                        capacity="320 TEU"
                                        plugs="100"
                                        built="2006"
                                        status="Chartered"
                                        flag="Argentina"

                                    />

                                </div>
                                <div className="col-sm-3">
                                    <VesselSmall
                                        currentLanguage={props.currentLanguage}
                                        name="Care II"
                                        vesselType="Barge"
                                        length="78m"
                                        beam="23.6m"
                                        capacity="320 TEU"
                                        plugs="100"
                                        built="2009"
                                        status="Chartered"
                                        flag="Argentina"

                                    />

                                </div>


                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
