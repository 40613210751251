import React from 'react'
import '../App.css'
import Services2 from './services2.JPG'
import Services3 from './services3.JPG'
import Services4 from './services4.JPG'

export default function Services(props) {
  return (

    <>
      {props.currentLanguage === "English"
        ?
        <>
          <div className="section-title2">Servicios</div>
          <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={Services4} className="d-block w-100" alt="..." />
                <div className="carousel-caption">
                  <h4>Convoyes adaptables</h4>
                  <p className="carousel-caption d-none d-md-block site-font">Actualmente empleamos 3 remolcadores de empuje y 4 barcazas lo cual nos permite ofrecer un servicio flexible y dinámico. Cada barcaza tiene una capacidad de transporte de 320 teus con hasta 100 conexiones para containers frigorífico.</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={Services2} className="d-block w-100" alt="..." />
                <div className="carousel-caption">
                  <h4>Conexiones portuarias</h4>
                  <p className="carousel-caption d-none d-md-block site-font">Nuestro servicio regular conecta las terminales portuarias de Asuncion (PY) con las terminales de trasbordo en Buenos Aires y Montevideo. Otros puertos fluviales pueden ser adicionados al servicio.</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={Services3} className="d-block w-100" alt="..." />
                <div className="carousel-caption">
                  <h4>Cobertura extensible</h4>
                  <p className="carousel-caption d-none d-md-block site-font">Nuestro servicio puede cubrir otros puertos fluviales presentes en los ríos Paraná y Paraguay.</p>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Anterior</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Siguiente</span>
            </button>
          </div>
        </>
        :
        <>
          <div className="section-title2"> Services</div>
          <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={Services4} className="d-block w-100" alt="..." />
                <div className="carousel-caption">
                  <h4>Adaptable Convoys</h4>
                  <p className="carousel-caption d-none d-md-block site-font">We currently employ 3 tug boats and 4 barges which allow us to offer a flexible and dynamic service. Each barge has the capacity to transport up to 320 teus offering up to 100 connections for refrigerated cargo.</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={Services2} className="d-block w-100" alt="..." />
                <div className="carousel-caption">
                  <h4>Port Connection</h4>
                  <p className="carousel-caption d-none d-md-block site-font">Our main Services connect port terminals of Asuncion (PY) with Buenos Aires and Montevideo transhipment’s Ports. Other fluvial ports can be added to our port scope.</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={Services3} className="d-block w-100" alt="..." />
                <div className="carousel-caption">
                  <h4>Adjustable Coverage</h4>
                  <p className="carousel-caption d-none d-md-block site-font">Service coverage can be extended to other fluvial Ports at Parana and Paraguay River.</p>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </>

      }
    </>
  )
}
