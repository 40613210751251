import React from 'react'

export default function AboutUs(props) {


    return (
        <>
            <div className="blue">
                {props.currentLanguage === "English"
                    ? <div class="card text-center border-0">
                        <div class="card-body">
                            <h5 class="card-title"><div className="section-title2">Acerca de nosotros</div></h5>
                            <p class="card-text site-font">Somos una empresa naviera localizada en Paraguay con mas de 10 años en el mercado, totalmente dedicados al transporte de carga containerizada y carga especial a través de los ríos Paraguay, Paraná y de la Plata.
                                Nuestra experiencia en el tráfico, junto con nuestra cuota del mercado nos hacen una empresa líder en este tráfico.</p>
                            <a href="mailto:agencia@conaysa.com?subject=Contacto%20desde%20p%C3%A1gina%20web" class="btn btn-primary">Contacto</a>
                        </div>
                    </div>
                    : <div class="card text-center border-0">

                        <div class="card-body ">
                            <h5 class="card-title"><div className="section-title2">About Us</div></h5>
                            <p class="card-text site-font">We are Feeder company located at Paraguay, with more than 10 years in the market, fully dedicated to container and special cargo transportation throughout Paraguay, Parana and de la Plata rivers.
                                Our experience in the traffic, along with our market-share, make us a leader in this trade.</p>
                            <a href="mailto:agencia@conaysa.com?subject=Contact%20from%20website" class="btn btn-primary">Contact us</a>
                        </div>

                    </div>
                }
            </div>
            {props.currentLanguage === "English"
                ?
                <div className="row">
                    <div className="col-sm-6">
                        <div className="card border-0">
                            <div className="card-body">
                                <h5 className="card-title colorAzul">Qué hacemos</h5>
                                <p className="card-text site-font">Ofrecemos un servicio de barcazas para el transporte de carga containerizada tanto seca como frigorífica así como cargas especiales, conectando Asunción con las terminales portuarias a través de la Hidrovía Parana-Paraguay incluyendo Buenos Aires y Montevideo.</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card border-0">
                            <div className="card-body">
                                <h5 className="card-title colorAzul">Qué le ofrecemos</h5>
                                <p className="card-text site-font">La amplia experiencia de nuestro equipo de trabajo relacionada con el transporte marítimo y fluvial tanto regional como internacional nos permite saber que necesita el cliente en forma eficiente y confiable.<br />
                                    Nuestra flota de remolcadores y barcazas nos da la posibilidad de ofrecer diferentes tipos de servicio de acuerdo a las necesidades del cliente.<br />
                                    Nuestra capacidad de transporte es una de las mayores que operan en este tráfico.</p>

                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-sm-6">
                        <div className="card border-0">
                            <div className="card-body">
                                <h5 className="card-title colorAzul">What we do</h5>
                                <p className="card-text site-font">We provide a barge service for the transportation of containerized reefer and dry as well as special cargo, connecting port terminals through the Hidrovia Parana-Paraguay including Buenos and Montevideo Ports.</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card border-0">
                            <div className="card-body">
                                <h5 className="card-title colorAzul">What we offer you</h5>
                                <p className="card-text site-font">he background of our team related to Shipping transportation in regional and international trades make us to know what it takes to offer an efficient and reliable Service.
                                    Our Fleet of tug boats and barges give us the possibility to offer different kind of transportation services according customers´requirements.
                                    Our total transportation capacity is one of the largest in size operating in this trade.</p>
                            </div>
                        </div>
                    </div>
                </div>
            }





            {/*
            <div className="blue">
                {props.currentLanguage === "English"
                    ? <div className="section-title2">Acerca de nosotros</div>
                    : <div className="section-title2">About Us</div>
                }

                {props.currentLanguage === "English"
                    ? <div className="text-center-white site-font">Somos una empresa naviera localizada en Paraguay con mas de 10 años en el mercado, totalmente dedicados al transporte de carga containerizada y carga especial a través de los ríos Paraguay, Paraná y de la Plata.<br />Nuestra experiencia en el tráfico, junto con nuestra cuota del mercado nos hacen una empresa líder en este tráfico.</div>
                    : <div className="text-center-white site-font">We are Feeder company located at Paraguay, with more than 10 years in the market, fully dedicated to container and special cargo transportation throughout Paraguay, Parana and de la Plata rivers.<br />Our experience in the traffic, along with our market-share, make us a leader in this trade.</div>
                }

                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-6">
                            {props.currentLanguage === "English"
                                ? <h5 className="left title-color">Qué Hacemos</h5>
                                : <h5 className="left title-color">What we do</h5>
                            }

                            {props.currentLanguage === "English"
                                ? <p className="left">Ofrecemos un servicio de barcazas para el transporte de carga containerizada tanto seca como frigorífica así como cargas especiales, conectando Asuncion con las terminales portuarias a través de la Hidrovía Parana-Paraguay incluyendo Buenos Aires y Montevideo</p>
                                : <p className="left">We provide a barge service for the transportation of containerized reefer and dry as well as special cargo, connecting port terminals through the Hidrovia Parana-Paraguay including Buenos and Montevideo Ports.</p>
                            }

                        </div>

                        <div className="col-6">
                            {props.currentLanguage === "English"
                                ? <>
                                    <h5 className="right title-color">Qué le ofrecemos</h5>
                                    <p className="right">La amplia experiencia de nuestro equipo de trabajo relacionada con el transporte marítimo y fluvial tanto regional como internacional nos permite saber que necesita el cliente en forma eficiente y confiable.<br />
                                        Nuestra flota de remolcadores y barcazas nos da la posibilidad de ofrecer diferentes tipos de servicio de acuerdo a las necesidades del cliente.<br />
                                        Nuestra capacidad de transporte es una de las mayores que operan en este tráfico.</p>
                                </>
                                :
                                <>
                                    <h5 className="right title-color">What we offer you</h5>
                                    <p className="right">The background of our team related to Shipping transportation in regional and international trades make us to know what it takes to offer an efficient and reliable Service.<br />
                                        Our Fleet of tug boats and barges give us the possibility to offer different kind of transportation services according customers´requirements.<br />
                                        Our total transportation capacity is one of the largest in size operating in this trade.
                                    </p>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
                        */}
        </>
    )
}
