import './App.css';
import React, { useRef, useState, useEffect } from 'react';
import Navbar from './components/Navbar.jsx';
import Cover from './components/CoverVideo.jsx';
import AboutUs from './components/AboutUs.jsx';
import Footer from './components/Footer.jsx';
import Services from './components/Services.jsx';
import Fleet from './components/Fleet.jsx';
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'

function App() {
  const aboutUsRef = useRef();
  const servicesRef = useRef();
  const fleetRef = useRef();
  const contactRef = useRef();
  const executeScrolltoAboutUs = () => aboutUsRef.current.scrollIntoView()
  const executeScrolltoServices = () => servicesRef.current.scrollIntoView()
  const executeScrolltoFleet = () => fleetRef.current.scrollIntoView()
  const executeScrolltoContact = () => contactRef.current.scrollIntoView()

  const [currentLanguage, setCurrentLanguage] = useState("Español")

  function toggleLanguage(){
    if (currentLanguage === "Español"){
        setCurrentLanguage("English")
    } else {
        setCurrentLanguage("Español")
    }
  }

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    console.log("Language Changed to "+currentLanguage);
  },[currentLanguage]);




  return (
    <>
    
      <Navbar  
        executeScrolltoAboutUs={executeScrolltoAboutUs}
        executeScrolltoServices={executeScrolltoServices}
        executeScrolltoFleet={executeScrolltoFleet}
        executeScrolltoContact={executeScrolltoContact}
        toggleLanguage={toggleLanguage}
        currentLanguage={currentLanguage}
      />
      <Cover currentLanguage={currentLanguage}/>
      <div ref={aboutUsRef}></div>
      <AboutUs currentLanguage={currentLanguage}/>
      <div ref={servicesRef}></div>
      <Services currentLanguage={currentLanguage}/>
      <div ref={fleetRef}></div>
      <Fleet currentLanguage={currentLanguage}/>
      <div ref={contactRef}></div>
      <Footer currentLanguage={currentLanguage}/>
      
      {currentLanguage === 'English' 
        ?
        <FloatingWhatsApp 
          phone='+595213392255'
          size='50px'
          position='right'
          popupMessage='Hola, ¿Cómo podemos ayudarle?' 
          headerTitle='Estamos en WhatsApp'
          autoOpenTimeout='2000'
        />
        :
        <FloatingWhatsApp 
          phone='+595213392255'
          size='50px'
          position='right'
          popupMessage='Hi, How can we help you?' 
          headerTitle='We are on WhatsApp'
          autoOpenTimeout='2000'
        />
      }
    </>
  );
}

export default App;
