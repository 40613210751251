import React from 'react'

export default function Footer(props) {
    return (
        <>
            {props.currentLanguage === "English"
                ?
                <>
                    <nav className="navbar navbar-light bg-light">
                        <div className="container-fluid footer">

                            <div className="container ">
                                <div className="row justify-content-around">
                                    <div className="col text-center">
                                        <strong>CONAY S.A.</strong><br />
                                        Oficina<br />
                                        +595213392255<br />
                                        Estrella 692 esq. O'leary<br />
                                        Edificio Líder IV piso 12 Oficina 128<br />
                                        Asunción<br />
                                        Paraguay<br />
                                    </div>
                                    <div className="col text-center">
                                        <a href="mailto:info@conay.com.py" className="link-light">info@conay.com.py<br /></a>
                                    </div>
                                    <div className="col text-center">
                                        WhatsApp: +595213392255<br /><br /><br /><br /><br />conaysa.com © 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </>
                :
                <>
                    <nav className="navbar navbar-light bg-light">
                        <div className="container-fluid footer">

                            <div className="container ">
                                <div className="row justify-content-around">
                                    <div className="col text-center">
                                        <strong>CONAY S.A.</strong><br />
                                        Front Desk<br />
                                        +595213392255<br />
                                        Estrella 692 esq. O'leary<br />
                                        Edificio Líder IV piso 12 Oficina 128<br />
                                        Asunción<br />
                                        Paraguay<br />
                                    </div>
                                    <div className="col text-center">
                                        <a href="mailto:info@conay.com.py" className="link-light">agencia@conaysa.com<br /></a>
                                    </div>
                                    <div className="col text-center">
                                        WhatsApp: +595213392255<br /><br /><br /><br /><br />conaysa.com © 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </>
            }
        </>
    )
}
