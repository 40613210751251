import React from 'react'
import FotoPiravera from './fotoPiravera.png'
import FotoPirareta from './fotoPirareta.png'
import FotoPirayu from './fotoPirayu.png'
import FotoConayI from './fotoConayI.png'
import FotoConayII from './fotoConayII.png'
import FotoCareI from './fotoCareI.png'
import FotoCareII from './fotoCareII.png'

export default function VesselSmall(props) {
    return (
        <div className="card" >
            {props.name === 'PIRAVERA' ? <img src={FotoPiravera} className="card-img-top img-medium" alt="..." /> : null}
            {props.name === 'RIO PIRARETA' ? <img src={FotoPirareta} className="card-img-top img-medium" alt="..." /> : null}
            {props.name === 'PIRAYU' ? <img src={FotoPirayu} className="card-img-top img-medium" alt="..." /> : null}
            {props.name === 'Conay I' ? <img src={FotoConayI} className="card-img-top img-medium" alt="..." /> : null}
            {props.name === 'Conay II' ? <img src={FotoConayII} className="card-img-top img-medium" alt="..." /> : null}
            {props.name === 'Care I' ? <img src={FotoCareI} className="card-img-top img-medium" alt="..." /> : null}
            {props.name === 'Care II' ? <img src={FotoCareII} className="card-img-top img-medium" alt="..." /> : null}

            <div className="card-body">
                <h5 className="card-title">{props.name}</h5>
                <p className="card-text">{props.vesselType}</p>
            </div>
            {props.currentLanguage === "English"
                ?
                <>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Eslora: <br></br><strong>{props.length}</strong></li>
                        <li className="list-group-item">Manga:<br></br> <strong>{props.beam}</strong></li>
                        {props.engine && <li className="list-group-item">Motor:<br></br> <strong>{props.engine}</strong></li>}
                        {props.capacity && <li className="list-group-item">Capacidad:<br></br> <strong>{props.capacity}</strong></li>}
                        {props.plugs && <li className="list-group-item">Enchufes:<br></br> <strong>{props.plugs}</strong></li>}
                        <li className="list-group-item">Construído:<br></br> <strong>{props.built}</strong></li>
                        {/*<li className="list-group-item">Status:<br></br> <strong>{props.status}</strong></li>*/}
                        <li className="list-group-item">Bandera:<br></br> <strong>{props.flag}</strong></li>
                    </ul>

                    {props.marineTrafficLink ?
                        <div className="card-body">
                            <a href={props.marineTrafficLink} className="card-link">Seguir en Marine Traffic</a>
                        </div>
                        : null}
                </>
                :
                <>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Length: <br></br><strong>{props.length}</strong></li>
                        <li className="list-group-item">Beam:<br></br> <strong>{props.beam}</strong></li>
                        {props.engine && <li className="list-group-item">Engine:<br></br> <strong>{props.engine}</strong></li>}
                        {props.capacity && <li className="list-group-item">Capacity:<br></br> <strong>{props.capacity}</strong></li>}
                        {props.plugs && <li className="list-group-item">Plugs:<br></br> <strong>{props.plugs}</strong></li>}
                        <li className="list-group-item">Built:<br></br> <strong>{props.built}</strong></li>
                        {/*<li className="list-group-item">Status:<br></br> <strong>{props.status}</strong></li>*/}
                        <li className="list-group-item">Flag:<br></br> <strong>{props.flag}</strong></li>
                    </ul>

                    {props.marineTrafficLink ?
                        <div className="card-body">
                            <a href={props.marineTrafficLink} className="card-link">Follow on Marine Traffic</a>
                        </div>
                        : null}
                </>
            }


        </div>
    )
}
