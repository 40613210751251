import React, { useState, useEffect} from 'react'
import cover_video from './conay_cover.mp4'

export default function CoverVideo(props) {
  
  const [msgList, setMsgList] = useState(["We connect Paraguay to rest of the World.",
                                          "Your cargo...", 
                                          "Simply delivered.",
                                          "Simply delivered."                                            
                                          ])  
                                          
    
  
  useEffect(() => {
    if (props.currentLanguage === "Español"){
      setMsgList(["We connect Paraguay to rest of the World.",
      "Your cargo...", 
      "Simply delivered.",
      "Simply delivered."                                            
                  ])  
    }    
    if (props.currentLanguage === "English"){
      setMsgList(["Conectamos Paraguay y la hidrovía con el mundo.",
                  "Su carga...", 
                  "Transportada.",
                  "Transportada."                                            
                  ])  
    }
  },[props.currentLanguage]);
    
    //const [msgList, setMsgList] = useState(["We connect Paraguay to rest of the World.",
    //                                        "Your cargo...", 
    //                                        "Simply delivered.",
    //                                        "Simply delivered."                                            
    //                                        ])

    const [msgQty] = useState(msgList.length)
    const [currentMsg, setCurrentMsg] = useState(0)

    const useInterval = (callback, delay) => {
        const savedCallback = React.useRef();
      
        React.useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
      
        React.useEffect(() => {
          function tick() {
            savedCallback.current();
          }
          if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
          }
        }, [delay]);
      };

     
    useInterval(() => {
      console.log("currentmsg = "+currentMsg+"    msgListLen="+msgList.length)
          if (currentMsg === msgQty){
              //console.log("nada")
              setCurrentMsg(0)
          }else{
            setCurrentMsg(currentMsg+1)
          }
        }, 5000);
     

    return (
      <>
        <div className="cover-video-container">
            <video className="video-cover video" src={cover_video} autoPlay={true} loop={true} muted ></video>
            <h1 className="video-overlay">{msgList[currentMsg]}</h1>
        </div>
      </>
    )
}
